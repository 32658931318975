@import '../variables.scss';

#about-me {
  .heading {
    margin: 1rem 6rem;
    padding: 0 1rem;

    .pre-heading {
      margin: 0.4rem 0;
      font-size: 1.3rem;
    }
  }

  .content {
    margin: 0rem 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4rem;

    .container {
      flex: 1 1 340px;
      padding: 0 1rem;
    }

    .sub-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 4rem;

      div {
        flex: 1 1 350px;
      }
    }

    img {
      @media screen and (max-width: $breakpoint-L) {
        width: 70%;
      }
    }
  }
}
