footer {
  position: relative;
  padding: 0.1em 1em;
  margin-top: 1rem;
  background-color: #000;
  color: #fff;
  text-align: center;
  vertical-align: center;
  font-size: 0.9rem;
}

footer a {
  text-decoration: none;
  color: #e60000;

  :hover {
    color: #fff;
  }
}

.copyright-msg span {
  color: #e60000;
}

.portal-link {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1.2rem;
  left: 1.2rem;

  span {
    font-size: 0.8rem;
    color: #ffffff;
  }

  img {
    max-height: 1.2rem;
    margin-right: 0.25rem;
    filter: invert(9%) sepia(98%) saturate(5659%) hue-rotate(358deg) brightness(109%) contrast(123%);
  }
}
