@import '../variables.scss';

.album-card {
  width: 100%;
  padding: 1.5rem;
  background: white;
  color: #fff;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  cursor: pointer;
  aspect-ratio: 15/23;

  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
  }

  &:hover {
    transform: translateY(10px);

    &:before {
      opacity: 1;
    }

    .album-card-info {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.5);
    z-index: 2;
    transition: 1s;
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .album-card-info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;

    @media screen and (max-width: $breakpoint-S) {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
