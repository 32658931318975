.masonry {
  columns: 4;
  column-gap: 16px;
  padding: 2rem;
}
@media (max-width: 1200px) {
  .masonry {
    columns: 3;
  }
}
@media (max-width: 992px) {
  .masonry {
    columns: 2;
  }
}
@media (max-width: 480px) {
  .masonry {
    columns: 1;
  }
}
