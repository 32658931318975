.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  button {
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    margin: 0 1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
      color: #181818;
      background-color: #fff;
      cursor: not-allowed;
    }
  }
}
